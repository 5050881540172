import { getTimestamp } from "./time";
export const downloadCSVFile = (name, file) => {
    const timestamp = getTimestamp();
    const extension = ".csv";
    const filename = timestamp + name + extension;
    const csv = file.join("\n");
    const csvFile = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.download = filename;
    link.href = window.URL.createObjectURL(csvFile);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
};
export const orderIsPresent = (order, orders) => {
    let isPresent = false;
    for (let index = 0; index < orders.length; index++) {
        if (orders[index].accession === order) {
            isPresent = true;
            break;
        }
    }
    return isPresent;
};
export const processTextFieldInput = (ordersInput) => {
    let ordersToUpdate;
    const orderRegex = /^\w*(?:([A-Za-z]+-\d+)[\w|,|;|\s]*)+$/m;
    const delimiterRegex = /\s*[,;\n]+/m;
    const regexOutput = ordersInput.match(orderRegex);
    if (regexOutput === null) {
        ordersToUpdate = null;
    }
    else {
        const trimmedOutput = regexOutput[0].replace(/ /g, ""); // Removes literal spaces & specifying [0] gives the first complete match as opposed to the output of capturing groups
        const delimitedOutput = trimmedOutput.replace(/\s/g, ","); // Replaces new lines with a comma
        const ordersArray = delimitedOutput.split(delimiterRegex); // Splits orders into an array
        const orderValues = ordersArray.filter(Boolean); // Removes falsy values("", 0, NaN, null, undefined, and false)
        ordersToUpdate = orderValues.filter(function () { return true; }); // Returns values ignoring repeated commas and semi colons
    }
    return ordersToUpdate;
};
export const checkOperatorEmail = (operator) => {
    const emailPattern = /.+@ultromics\.com/m;
    const email = operator.match(emailPattern);
    let operatorEmail;
    if (operator === "") {
        operatorEmail = [""];
    }
    else if (email) {
        operatorEmail = email[0];
    }
    return operatorEmail;
};
export const isValueNotApplicable = (element) => {
    return element === 'N/A';
};
