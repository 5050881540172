export default {
    computed: {
        canSend() {
            if (!this.selected)
                return false;
            const study = this.studies.filter(s => s.accession === this.selected)[0];
            if (!study)
                return false;
            if (study.status !== 'complete') {
                return true;
            }
            return true;
        }
    },
    data: () => {
        return {
            selected: null
        };
    },
    props: {
        studies: Array
    },
    methods: {
        sendSelected() {
            this.$emit('send-study', this.selected);
        }
    }
};
