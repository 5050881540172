import axios from 'axios';
import env from '@/env.json';
const baseURL = env.VUE_APP_VNA_API;
const httpClient = axios.create({
    baseURL
});
export default {
    pushStudy: async (accession, ae_title, host, port) => {
        try {
            const result = await httpClient.post('/tools/c-move', {
                accession: accession,
                aetitle: ae_title,
                host: host,
                port: port
            });
            return {
                success: result.status === 200,
                message: result.data
            };
        }
        catch (e) {
            return {
                success: false,
                message: e.toString()
            };
        }
    },
    ping: async (ae_title, host, port) => {
        try {
            const result = await httpClient.post('/tools/c-echo', {
                aetitle: ae_title,
                host: host,
                port: port
            });
            return {
                success: result.status === 200,
                message: result.data
            };
        }
        catch (e) {
            return {
                success: false,
                message: e.toString()
            };
        }
    },
    setAuthenticationToken: (token) => {
        // currently unused
        httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
};
