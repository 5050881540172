import Vue from 'vue';
import Vuex from 'vuex';
import clientService from '@/services';
import vnaService from '@/services/vna';
import orderService from '@/services/order';
import { PublicClientApplication } from "@azure/msal-browser";
import router from "@/router";
import env from '@/env.json';
Vue.use(Vuex);
export default new Vuex.Store({
    strict: env.NODE_ENV !== 'production',
    state: {
        loaded: false,
        loading: false,
        config: {},
        flash_message: 'No flash message',
        flash_status: null,
        flash_show: false,
        selected_customer_id: null,
        selected_site_id: null,
        customers: [],
        sites: [],
        products: [],
        studies: [],
        orders: [],
        updatedOrders: [],
        msalObj: {},
        msalConfig: {},
        userAccessToken: "",
        isUserAvailable: false
    },
    getters: {
        ready: state => state.loaded,
        getLoading: state => state.loading,
        getConfig: state => state.config,
        getMsalObj: state => state.msalObj,
        getMsalConfig: state => state.msalConfig,
        getIsUserAvailable: state => state.isUserAvailable,
        getFlash: state => { return { message: state.flash_message, status: state.flash_status, show: state.flash_show }; },
        getCustomer: state => state.customers.find(customer => customer.rowKey === state.selected_customer_id),
        getSelectedCustomer: state => state.customers.find(customer => customer.rowKey === state.selected_customer_id),
        getCustomers: state => {
            const customers = [...state.customers]
                .sort((a, b) => 0 - (a.prefix > b.prefix ? -1 : 1));
            return customers;
        },
        getSites: state => {
            const sites = [...state.sites]
                .filter(site => site.contract === state.selected_customer_id);
            return sites;
        },
        getSelectedSite: state => state.sites.find(site => site.rowKey === state.selected_site_id),
        getProducts: state => state.products,
        getStudies: state => state.studies,
        getOrders: state => state.orders,
        getUpdatedOrders: state => state.updatedOrders,
        getBreadcrumbNavigation: state => {
            const crumbs = [
                { text: 'Home', to: { name: 'home' } }
            ];
            const customer = state.customers.find(customer => customer.rowKey === state.selected_customer_id);
            const site = state.sites.find(site => site.rowKey === state.selected_site_id);
            if (customer) {
                crumbs.push({
                    text: customer.name,
                    to: { name: 'customer', params: { customer_id: customer.rowKey } }
                });
                if (site) {
                    crumbs.push({
                        text: site.name,
                        to: { name: 'site', params: { site_id: site.rowKey } }
                    });
                }
            }
            return crumbs;
        }
    },
    mutations: {
        setConfig: (state, config) => {
            state.config = config;
        },
        setMsalConfig: (state, msalConfig) => {
            state.msalConfig = msalConfig;
        },
        setSelectedCustomer: (state, customer_id) => {
            // selecting a new customer removes any selected site
            if (state.selected_customer_id && customer_id !== state.selected_customer_id) {
                state.selected_site_id = null;
            }
            state.selected_customer_id = customer_id;
        },
        setSelectedSite: (state, site_id) => {
            // selecting a site also selects the associated customer
            state.selected_site_id = site_id;
            const site = state.sites.find(site => site.rowKey === site_id);
            if (site) {
                state.selected_customer_id = site.contract;
            }
        },
        refreshStudyList: (state, studies) => {
            state.studies = studies;
        },
        refreshAll: (state, data) => {
            state.customers = data.customers;
            state.sites = data.sites;
            state.products = data.products;
        },
        setFlash: (state, { status, message }) => {
            state.flash_message = message;
            state.flash_status = status;
            state.flash_show = true;
        },
        clearFlash: (state) => {
            state.flash_show = false;
        },
        clearOrders: (state) => {
            state.orders = [];
        },
        clearUpdatedOrders: (state) => {
            state.updatedOrders = [];
        },
        setReady: (state) => {
            state.loaded = true;
        },
        setLoading: (state, isActive) => {
            state.loading = isActive;
        },
        addOrder: (state, order) => {
            const existsAtIndex = state.orders.findIndex(o => o.accession === order.accession);
            // If order exists in the state replace order in state else push it to the state
            if (existsAtIndex !== -1) {
                state.orders[existsAtIndex] = order;
            }
            else {
                state.orders.push(order);
            }
            if (state.orders.length) {
                state.orders.sort((a, b) => new Intl.Collator('en', { numeric: true, sensitivity: 'base' }).compare(a.accession, b.accession));
            }
        },
        addUpdatedOrder: (state, updatedOrder) => {
            state.updatedOrders.push(updatedOrder);
            if (state.updatedOrders.length) {
                state.updatedOrders.sort((a, b) => new Intl.Collator('en', { numeric: true, sensitivity: 'base' }).compare(a.accession, b.accession));
            }
        },
        setMsalObj(state, data) {
            state.msalObj = data;
        },
        setUserAccessToken(state, token) {
            state.userAccessToken = token;
        },
        loginRedirect(state) {
            state.msalObj.loginRedirect({
                scopes: ["User.Read"]
            });
        },
        setIsUserAvailable(state, isUserAvailable) {
            state.isUserAvailable = isUserAvailable;
        }
    },
    actions: {
        loginRedirect: (context) => context.commit('loginRedirect'),
        setMsalObj: (context, msalObj) => context.commit('setMsalObj', msalObj),
        handleRedirect: async (context, msalConfig) => {
            try {
                const redirectResponse = await context.state.msalObj.handleRedirectPromise();
                if (!redirectResponse)
                    return;
                context.state.msalObj.setActiveAccount(redirectResponse.account);
                await context.dispatch("initiateLoggedInState", msalConfig);
                router.push({ name: 'home' });
            }
            catch (error) {
                console.error(error); // eslint-disable-line
            }
        },
        initiateLoggedInState: async (context, msalConfig) => {
            context.commit('setLoading', true);
            const msalObj = new PublicClientApplication(msalConfig);
            const tokenRequestObj = {
                scopes: ["User.Read"],
                account: msalObj.getActiveAccount()
            };
            try {
                const authResult = await msalObj.acquireTokenSilent(tokenRequestObj);
                context.commit('setUserAccessToken', authResult.accessToken);
                clientService.setAuthenticationToken(authResult.accessToken);
                orderService.setAuthenticationToken(authResult.accessToken);
                await context.dispatch("refresh");
                if (router.currentRoute.path.startsWith("/s/")) {
                    await context.dispatch("refreshStudies", { limit: "all" });
                }
                context.commit("setIsUserAvailable", true);
                context.commit("setReady");
            }
            catch (error) {
                console.warn(error); // eslint-disable-line
            }
            context.commit('setLoading', false);
        },
        setReady: (context) => context.commit("setReady"),
        toggleLoading: (context, isActive) => context.commit('setLoading', isActive),
        selectCustomer: (context, customer_id) => context.commit('setSelectedCustomer', customer_id),
        selectSite: (context, site_id) => context.commit('setSelectedSite', site_id),
        refreshStudies: async (context) => {
            context.commit('refreshStudyList', []);
            context.commit('setLoading', true);
            const response = await clientService.getStudies(context.state.selected_site_id, '', '', '10');
            if (response.studies.length === 0) {
                context.commit('setLoading', false);
            }
            context.commit('refreshStudyList', response.studies);
            context.commit('setLoading', false);
        },
        selectOrder: async (context, accession) => {
            const order = await clientService.getOrderByID(accession);
            // if an order is invalid it will return as an empty array
            if (!Array.isArray(order)) {
                context.commit('addOrder', order);
            }
        },
        updateOrder: async (context, updatedOrderDetails) => {
            const updatedOrder = await orderService.updateOrderDetails(updatedOrderDetails);
            context.commit('addUpdatedOrder', updatedOrder);
        },
        emptyOrders: (context) => context.commit('clearOrders'),
        emptyUpdatedOrders: (context) => context.commit('clearUpdatedOrders'),
        refresh: async (context, isOnWelcomePage) => {
            if (!isOnWelcomePage) {
                const site = context.getters.getSelectedSite;
                if (site) {
                    context.commit('setSelectedCustomer', site.contract);
                }
                const data = await clientService.getMainData();
                context.commit('refreshAll', data);
            }
            context.commit('setReady');
        },
        dicomSendTestResult: async (context, { ae_title, host, port }) => {
            // this is a pre-canned result not associated with any study...
            const cmove = await vnaService.pushStudy('TEST-0', ae_title, host, port);
            context.dispatch('setFlash', cmove);
        },
        dicomSendStudy: async (context, { accession, ae_title, host, port }) => {
            const cmove = await vnaService.pushStudy(accession, ae_title, host, port);
            context.dispatch('setFlash', cmove);
        },
        dicomPing: async (context, { ae_title, host, port }) => {
            const cecho = await vnaService.ping(ae_title, host, port);
            context.dispatch('setFlash', cecho);
        },
        setFlash: async (context, { message, status }) => {
            context.commit('setFlash', { status, message });
            setTimeout(() => context.commit('clearFlash'), 4000);
        }
    },
    modules: {}
});
