import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import Customer from '@/views/Customer.vue';
import Welcome from '@/views/Welcome.vue';
import Site from '@/views/Site.vue';
import BulkOrderUpdate from '@/views/BulkOrderUpdate.vue';
import BulkOrderStatus from '@/views/BulkOrderStatus.vue';
import store from '@/store';
import env from '@/env.json';
Vue.use(VueRouter);
const routes = [
    {
        path: '/welcome',
        name: 'welcome',
        component: Welcome,
        beforeEnter: (_to, _from, next) => {
            if (store.state.msalObj.getActiveAccount()) {
                next({ name: 'home' });
            }
            else {
                store.dispatch("setReady");
                next();
            }
        }
    },
    {
        path: '/',
        name: 'home',
        component: Dashboard
    },
    {
        path: '/c/:customer_id/',
        name: 'customer',
        component: Customer,
        beforeEnter: (to, _, next) => {
            store.dispatch('selectCustomer', to.params.customer_id);
            next();
        }
    },
    {
        path: '/s/:site_id/',
        name: 'site',
        component: Site,
        beforeEnter: (to, _, next) => {
            store.dispatch('selectSite', to.params.site_id);
            next();
        }
    },
    {
        path: '/BulkOrderUpdate',
        name: 'BulkOrderUpdate',
        component: BulkOrderUpdate
    },
    {
        path: '/BulkOrderStatus',
        name: 'BulkOrderStatus',
        component: BulkOrderStatus
    }
];
const router = new VueRouter({
    mode: 'history',
    base: env.BASE_URL,
    routes
});
const unauthenticatedPaths = ['welcome'];
router.beforeEach(async (to, from, next) => {
    if (!store.state.msalObj.getActiveAccount() && !unauthenticatedPaths.includes(to.name)) {
        next({ name: 'welcome' });
    }
    else {
        next();
    }
});
export default router;
