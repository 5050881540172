import Subnavigation from '@/components/Subnavigation.vue';
import ProductList from '@/components/ProductList.vue';
import SiteList from '@/components/SiteList.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'customer',
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            crumbs: 'getBreadcrumbNavigation',
            customer: 'getCustomer',
            sites: 'getSites',
            products: 'getProducts'
        })
    },
    components: {
        ProductList,
        SiteList,
        Subnavigation
    },
    methods: {},
    props: {}
};
