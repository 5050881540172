import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            userData: {},
            loggedInUser: ""
        };
    },
    created() {
        if (this.msalObj.getActiveAccount()) {
            this.loggedInUser = this.msalObj.getActiveAccount().name;
        }
    },
    computed: {
        ...mapGetters({
            isUserAvailable: "getIsUserAvailable",
            msalObj: "getMsalObj"
        })
    },
    methods: {
        logout: function () {
            this.msalObj.logoutRedirect({
                account: this.msalObj.getAccountByUsername(this.msalObj.getActiveAccount().username),
                postLogoutRedirectUri: '/login'
            });
        }
    }
};
