<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Code</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="product in products" :key="product.rowKey">
        <th scope="row">{{ product.name }}</th>
        <td>{{ product.rowKey }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    products: Array
  }
};
</script>
