<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">ID</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="site in sites" :key="site.name">
        <th scope="row">{{ site.name }}</th>
        <td>
            <router-link :to="{ name: 'site', params: { site_id: site.rowKey }}">{{ site.rowKey }}</router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    sites: Array
  }
};
</script>
