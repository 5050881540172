<template>
  <div>
    <div style="clear: both; margin-top: 40px"></div>

    <loading-spinner></loading-spinner>

    <Navigation></Navigation>

    <div class="main container">
      <div :class="messageClass" role="alert" v-if="getFlash.show">
        {{ getFlash.message }}
      </div>
      <router-view v-if="ready" />
    </div>

    <footer class="footer">
      <div class="container">
        <span class="text-muted" style="float: right"
          >&copy; Copyright 2020-2022 Ultromics Ltd.</span
        >
        <span class="text-dark">Release: {{ version }}</span>
      </div>
    </footer>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import LoadingSpinner from "@/components/LoadingSpinner";
import { mapGetters } from 'vuex';
import env from '@/env.json';

export default {
  components: {
    Navigation,
    LoadingSpinner
  },
  computed: {
    ...mapGetters(["getConfig", "getFlash", "ready"]),
    version () {
      return env.VERSION;
    },
    messageClass () {
      if (this.getFlash.status) {
        return ["alert", "alert-success"];
      } else {
        return ["alert", "alert-warning"];
      }
    }
  }
};
</script>

<style>
@import "~bootswatch/dist/darkly/bootstrap.css";

html {
  position: relative;
  min-height: 100%;
}
body {
  margin-top: 90px;
  margin-bottom: 60px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

body > .container {
  padding: 60px 15px 0;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

form {
  display: inline;
  margin: 8px;
}
</style>
