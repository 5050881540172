import { mapGetters } from "vuex";
import { downloadCSVFile, isValueNotApplicable } from "../utils/bulkOrderUtils";
export default {
    computed: {
        ...mapGetters({
            orders: "getOrders",
            updatedOrders: "getUpdatedOrders"
        }),
        canDownload() {
            if (!this.orders.length)
                return false;
            if (this.orders.length === this.updatedOrders.length) {
                return true;
            }
            return true;
        }
    },
    updated() {
        this.$nextTick(() => {
            if (this.orders.length === this.updatedOrders.length) {
                this.downloadCSV();
            }
        });
    },
    methods: {
        retrieveUpdatedOrder(accession, element) {
            let updatedOrder;
            for (let index = 0; index < this.updatedOrders.length; index++) {
                if (this.updatedOrders[index].message.accession === accession) {
                    if (element === "success" && this.updatedOrders[index].success) {
                        updatedOrder = "Success";
                    }
                    else {
                        updatedOrder = this.updatedOrders[index].message[element]
                            ? this.updatedOrders[index].message[element]
                            : undefined;
                    }
                    break;
                }
                else if (element === "success") {
                    updatedOrder = "Failure";
                }
            }
            return updatedOrder;
        },
        generateRow(accession, element, oldOrder) {
            let rowData;
            const updatedOrder = this.retrieveUpdatedOrder(accession, element);
            if (!updatedOrder) {
                rowData = oldOrder[element] ? oldOrder[element] : "N/A";
            }
            else if (oldOrder[element] && oldOrder[element] !== updatedOrder) {
                rowData = `${oldOrder[element]} => ${updatedOrder}`;
            }
            else if (!oldOrder[element] && oldOrder[element] !== updatedOrder) {
                rowData = `N/A => ${updatedOrder}`;
            }
            else {
                rowData = oldOrder[element] ? oldOrder[element] : "N/A";
            }
            return rowData;
        },
        generateUpdateStatusMessage(accession) {
            let updateStatus = this.retrieveUpdatedOrder(accession, "success");
            if (updateStatus === undefined) {
                updateStatus = "No Update Required";
            }
            return updateStatus;
        },
        generateCSV(orders) {
            const file = [];
            const header = '#,Accession,Old Status,New Status,Old Product ID,New Product ID,Old Operator,New Operator,Update Successful';
            file.push(header);
            for (let i = 0; i < orders.length; i++) {
                const row = [];
                const rowIndex = i + 1;
                const oldStatus = orders[i].status ? orders[i].status : 'N/A';
                const oldProductId = orders[i].product_id ? orders[i].product_id : 'N/A';
                const oldOperator = orders[i].operator ? orders[i].operator : 'N/A';
                let newStatus = this.retrieveUpdatedOrder(orders[i].accession, "status") ? this.retrieveUpdatedOrder(orders[i].accession, "status") : 'N/A';
                let newProductId = this.retrieveUpdatedOrder(orders[i].accession, "product_id") ? this.retrieveUpdatedOrder(orders[i].accession, "product_id") : 'N/A';
                let newOperator = this.retrieveUpdatedOrder(orders[i].accession, "operator") ? this.retrieveUpdatedOrder(orders[i].accession, "operator") : 'N/A';
                if (!isValueNotApplicable(newStatus)) {
                    newStatus = oldStatus !== newStatus ? newStatus : 'No Change Required';
                }
                if (!isValueNotApplicable(newProductId)) {
                    newProductId = oldProductId !== newProductId ? newProductId : 'No Change Required';
                }
                if (!isValueNotApplicable(newOperator)) {
                    newOperator = oldOperator !== newOperator ? newOperator : 'No Change Required';
                }
                const updateStatus = this.generateUpdateStatusMessage(orders[i].accession);
                row.push(rowIndex, orders[i].accession, oldStatus, newStatus, oldProductId, newProductId, oldOperator, newOperator, updateStatus);
                file.push(row.join(','));
            }
            return file;
        },
        downloadCSV() {
            const file = this.generateCSV(this.orders);
            downloadCSVFile("_order_updates", file);
        }
    }
};
