import axios from 'axios';
import env from '@/env.json';
const baseURL = () => {
    return env.API_URL;
};
const httpClient = axios.create({
    baseURL: baseURL(),
    headers: {
        'Content-Type': 'application/json',
        'x-functions-key': env.VUE_APP_FUNCTION_KEY
    }
});
export default {
    getMainData: async () => {
        try {
            const uri = '/all';
            const response = await httpClient.get(uri, { params: {} });
            return response.data;
        }
        catch (error) {
            if (error.response.status === 404) {
                alert("No info returned");
            }
            else {
                alert("Error retrieving info");
            }
        }
    },
    getStudies: async (site_id, status, eligibleOperator, limit) => {
        try {
            const params = `?node_id=${site_id}&status=${status}&limit=${limit}&eligible_operator=${eligibleOperator}`;
            const uri = '/studies' + params;
            const response = await httpClient.get(uri);
            return response.data;
        }
        catch (error) {
            if (error.response.status === 404) {
                alert("No info returned");
            }
            else {
                alert("Error retrieving info");
            }
            return { studies: [] };
        }
    },
    getOrderByID: async (accession) => {
        try {
            const params = `?accession=${accession}`;
            const uri = '/studies' + params;
            const result = await httpClient.get(uri);
            if (result.status === 200) {
                return result.data;
            }
        }
        catch (e) {
            return [];
        }
    },
    setAuthenticationToken: (token) => {
        httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
};
