import CustomerList from '@/components/CustomerList.vue';
import Subnavigation from '@/components/Subnavigation.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'dashboard',
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            crumbs: 'getBreadcrumbNavigation',
            customers: 'getCustomers'
        })
    },
    components: {
        CustomerList,
        Subnavigation
    },
    methods: {}
};
