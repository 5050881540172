import Subnavigation from '@/components/Subnavigation.vue';
import StudyList from '@/components/StudyList.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'site',
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            crumbs: 'getBreadcrumbNavigation',
            customer: 'getCustomer',
            site: 'getSelectedSite',
            studies: 'getStudies'
        })
    },
    components: {
        StudyList,
        Subnavigation
    },
    mounted() {
        this.$store.dispatch('refreshStudies');
    },
    methods: {
        ...mapActions([
            'refreshStudies'
        ]),
        sendStudy(accession) {
            this.$store.dispatch('dicomSendStudy', {
                accession,
                ae_title: this.site.aetitle,
                host: this.site.host,
                port: this.site.port
            });
        },
        pingSite() {
            this.$store.dispatch('dicomPing', {
                ae_title: this.site.aetitle,
                host: this.site.host,
                port: this.site.port
            });
        },
        pushResult() {
            this.$store.dispatch('dicomSendTestResult', {
                ae_title: this.site.aetitle,
                host: this.site.host,
                port: this.site.port
            });
        }
    },
    props: {}
};
