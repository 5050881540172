import { mapGetters, mapActions } from "vuex";
import { orderIsPresent, processTextFieldInput, checkOperatorEmail } from "../utils/bulkOrderUtils";
export default {
    computed: {
        ...mapGetters({
            orders: "getOrders",
            updatedOrders: "getUpdatedOrders",
            products: "getProducts"
        }),
        sortedProducts() {
            const capitalisedProducts = [];
            for (const product of this.products) {
                const tempProduct = { ...product };
                if (typeof tempProduct["for"] !== 'undefined' && tempProduct["for"]) {
                    tempProduct["for"] = `${tempProduct["for"].charAt(0).toUpperCase()}${tempProduct["for"].slice(1)}`;
                }
                else {
                    tempProduct["for"] = "No Product";
                }
                capitalisedProducts.push(tempProduct);
            }
            return capitalisedProducts.sort((a, b) => { if (a.for > b.for) {
                return 1;
            }
            else {
                return -1;
            } });
        },
        canSubmit() {
            if (!this.inputIsValid)
                return false;
            if (this.inputIsValid === true) {
                return true;
            }
            return true;
        },
        canValidate() {
            if (!this.ordersInput)
                return false;
            if (this.ordersInput === true) {
                return true;
            }
            return true;
        }
    },
    data: () => {
        return {
            keepOperator: true,
            ordersInput: "",
            inputIsValid: false,
            status: null,
            product_id: null,
            operator: null,
            invalidOrders: [],
            errors: [],
            statusActive: false,
            productIdActive: false,
            statusClickCount: 0,
            productIdClickCount: 0,
            statusOptions: ["open", "planned", "preflight", "in progress", "intervene", "cancelled"]
        };
    },
    watch: {
        status: function () {
            this.eraseInput();
        },
        operator: function () {
            this.eraseInput();
        },
        product_id: function () {
            this.eraseInput();
        },
        ordersInput: function () {
            this.eraseInput();
        }
    },
    methods: {
        ...mapActions([
            "selectOrder",
            "updateOrder",
            "emptyOrders",
            "emptyUpdatedOrders",
            "toggleLoading"
        ]),
        selectStatus() {
            if (this.statusClickCount === 0) {
                this.statusActive = !this.statusActive;
                this.statusClickCount += 1;
            }
        },
        selectProductId() {
            if (this.productIdClickCount === 0) {
                this.productIdActive = !this.productIdActive;
                this.productIdClickCount += 1;
            }
        },
        eraseInput() {
            if (this.orders.length) {
                this.emptyOrders();
            }
            if (this.updatedOrders.length) {
                this.emptyUpdatedOrders();
            }
            this.inputIsValid = false;
            this.invalidOrders = [];
        },
        clearOrders() {
            this.statusClickCount = 0;
            this.productIdClickCount = 0;
            this.statusActive = false;
            this.productIdActive = false;
            this.ordersInput = "";
            this.product_id = null;
            this.status = null;
            this.operator = null;
            this.invalidOrders = [];
            this.errors = [];
            this.emptyOrders();
            this.emptyUpdatedOrders();
        },
        generateRow(order, element, field) {
            let rowData;
            if (field === "product_id" && element) {
                element = element.match(/\(([^)]+)\)/)[1];
            }
            if (field === "operator" && order && !this.keepOperator) {
                rowData = `${order} => N/A`;
            }
            else if (order === element || !element) {
                rowData = order || "N/A";
            }
            else if (element && this.inputIsValid && order && order !== element) {
                rowData = `${order} => ${element}`;
            }
            else if (element && this.inputIsValid && !order) {
                rowData = `N/A => ${element}`;
            }
            return rowData;
        },
        checkUpdateInput() {
            this.errors = [];
            // check that at least one of the input fields is not empty
            if (!this.product_id && !this.status && !this.operator) {
                this.errors.push("All update fields cannot be empty. Please provide at least one value to update.");
            }
            if (!processTextFieldInput(this.ordersInput)) {
                this.errors.push("Orders must be formatted correctly. E.g. ATT-2 and ATTAMB-1 are valid orders.");
            }
            if (this.operator && !checkOperatorEmail(this.operator)) {
                this.errors.push("Email addresses must correspond to valid Ultromics email addresses.");
            }
            if (!this.errors.length) {
                return true;
            }
        },
        async validateOrders() {
            if (!this.keepOperator) {
                this.operator = "";
            }
            if (this.orders.length) {
                this.emptyOrders();
            }
            if (this.updatedOrders.length) {
                this.emptyUpdatedOrders();
            }
            const ordersToUpdate = processTextFieldInput(this.ordersInput);
            if (this.checkUpdateInput(this.errors, this.product_id, this.status, this.operator, this.ordersInput)) {
                let hasLoaded; // eslint-disable-line
                this.toggleLoading(true);
                await Promise.all(ordersToUpdate.map(async (order) => {
                    // selectOrder is an action that retrieves order details from the API and triggers a call committing it to state
                    await this.selectOrder(order);
                }));
                this.invalidOrders = ordersToUpdate.filter((order) => orderIsPresent(order, this.orders) === false);
                if (this.orders.length) {
                    this.inputIsValid = true;
                }
                if (hasLoaded) {
                    window.clearTimeout(hasLoaded);
                }
                hasLoaded = setTimeout(() => {
                    this.toggleLoading(false);
                }, 1000);
            }
        },
        async submitOrders() {
            const status = this.status ? this.status : undefined;
            const operator = this.operator ? this.operator : undefined;
            const product_id = this.product_id ? this.product_id.match(/\(([^)]+)\)/)[1] : undefined;
            const updatedValues = {};
            this.orders.map(async (order) => {
                let hasLoaded; // eslint-disable-line
                this.toggleLoading(true);
                if (status && order.status !== status) {
                    updatedValues.status = status;
                }
                if (operator && order.operator !== operator) {
                    updatedValues.operator = operator;
                }
                if (!this.keepOperator) {
                    updatedValues.operator = "";
                }
                if (product_id && order.product_id !== product_id) {
                    updatedValues.product_id = product_id;
                }
                if (Object.values(updatedValues).length) {
                    updatedValues.accession = order.accession;
                    await this.updateOrder(updatedValues);
                }
                if (hasLoaded) {
                    window.clearTimeout(hasLoaded);
                }
                hasLoaded = setTimeout(() => {
                    this.toggleLoading(false);
                }, 2000);
            });
            this.$router.push("/BulkOrderStatus");
        }
    }
};
