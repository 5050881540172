// returns the datetime now in dd_MM_YY_hh_mm format
export const getTimestamp = () => {
    const date = new Date();
    const day = date.getUTCDate();
    const paddedDay = day.toString().length === 1 ? `0${day}` : day;
    const month = date.getUTCMonth() + 1;
    const paddedMonth = month.toString().length === 1 ? `0${month}` : month;
    const year = date.getUTCFullYear();
    const hour = date.getHours();
    const paddedHour = hour.toString().length === 1 ? `0${hour}` : hour;
    const minutes = date.getUTCMinutes();
    const paddedMinutes = minutes.toString().length === 1 ? `0${minutes}` : minutes;
    const timestamp = `${paddedDay}_${paddedMonth}_${year}_${paddedHour}_${paddedMinutes}`;
    return timestamp;
};
