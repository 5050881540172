import axios from 'axios';
import env from '@/env.json';
const baseURL = env.ORDER_API;
const httpClient = axios.create({
    baseURL
});
export default {
    updateOrderDetails: async ({ accession, status, product_id, operator }) => {
        try {
            const result = await httpClient.put("v1/" + accession, {
                status,
                product_id,
                operator
            });
            return {
                success: result.status === 200,
                message: result.data
            };
        }
        catch (e) {
            return {
                success: false,
                message: e.toString()
            };
        }
    },
    setAuthenticationToken: (token) => {
        httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
};
