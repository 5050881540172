import Vue from 'vue';
import App from './App.vue';
import { ToggleButton } from 'vue-js-toggle-button';
import router from './router';
import store from './store';
import AsyncComputed from 'vue-async-computed';
import * as msal from "@azure/msal-browser";
import env from './env.json';
Vue.use(AsyncComputed);
Vue.component('ToggleButton', ToggleButton);
const msalConfig = {
    auth: {
        clientId: env.VUE_APP_MSAL_CLIENTID,
        authority: `https://login.microsoftonline.com/${env.VUE_APP_MSAL_TENANTID}`,
        redirectUri: env.VUE_APP_MSAL_REDIRECTURL
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
};
const msalObj = new msal.PublicClientApplication(msalConfig);
store.dispatch("setMsalObj", msalObj);
if (!msalObj.getActiveAccount()) {
    store.dispatch("handleRedirect", msalConfig);
}
else {
    store.dispatch('initiateLoggedInState', msalConfig);
}
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
Vue.config.productionTip = false;
