<template>
  <div class="welcome">
    <div>
      <div>Welcome to Connect</div>
      <button
        class="btn btn-secondary my-2 my-sm-0"
        style="float: right"
        @click="login"
      >
        Log In
      </button>
    </div>
  </div>
</template>

<script>

import env from '@/env.json';

export default {
  name: 'welcome',
  components: {},
  methods: {
    login: function () {
      this.$store.state.msalObj.loginRedirect({
        scopes: ["User.Read"]
      });
    }
  },
  data () {
    return {
      url: env.BASE_URL
    };
  }
};
</script>
